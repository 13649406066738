.TopBar{
    .MuiTypography-root {
        color: #7D76CF;
    }
    .MuiTab-root {
        color: #7D76CF !important;
    }
    .Mui-selected {
        font-weight: bold;
    }
}
