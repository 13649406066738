.Ingreso.MuiCardHeader-root {
    background-color: #F1F9FE;
    .MuiTypography-h5 {
        color: #27A8E9;
    }
}

.GastoNecesario.MuiCardHeader-root {
    background-color: #FFF0F7;
    .MuiTypography-h5 {
        color: #FF2A91;
    }
}

.Gasto.MuiCardHeader-root {
    background-color: #FDF2F2;
    .MuiTypography-h5 {
        color: #DE3B40;
    }
}

.Ahorro.MuiCardHeader-root {
    background-color: #F4F4FB;
    .MuiTypography-h5 {
        color: #7D76CF;
    }
}

.Inversion.MuiCardHeader-root {
    background-color: #EEFDF3;
    .MuiTypography-h5 {
        color: #1DD75B;
    }
}

.CardBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Amount.MuiTypography-h5 {
    color: black !important;
}