.Ingreso.MuiPaper-root {
    background-color: #F1F9FE;
    .MuiTypography-h5 {
        color: #27A8E9;
    }
}

.Gasto.MuiPaper-root {
    background-color: #FFF0F7;
    .MuiTypography-h5 {
        color: #DE3B40;
    }
}

.Ahorro.MuiPaper-root {
    background-color: #F4F4FB;
    .MuiTypography-h5 {
        color: #7D76CF;
    }
}

.Inversion.MuiPaper-root {
    background-color: #EEFDF3;
    .MuiTypography-h5 {
        color: #1DD75B;
    }
}

.Neceserio.MuiPaper-root {
    background-color: #FFF0F7;
    .MuiTypography-h5 {
        color: #FF2A91;
    }
}